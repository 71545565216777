<template>
  <div>
    <v-dialog v-model="couponsDialog" width="600px">
      <v-card>
        <v-card-actions>
          <h3 class="font-weight-bold">{{ couponsIsEdit ? $t('edit') : $t('add') }} {{ $t('coupons.coupon') }}</h3>
          <v-spacer></v-spacer>
          <v-btn outlined class="mr-3" color="secondary" @click="couponsDialog = false">
            {{ $t('cancel') }}
          </v-btn>
          <v-btn color="primary" @click="saveCoupons">
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
        <v-divider />
        <v-card-text>
          <v-text-field
            v-model="couponsCoupon"
            label="Coupon*"
            outlined
            hide-details
            class="mb-4"
          ></v-text-field>
          <v-text-field
            v-model="couponsMaxClaim"
            label="Max Claim*"
            type="number"
            min="0"
            outlined
            hide-details
            class="mb-4"
          ></v-text-field>
          <v-text-field
            v-model="couponsDiscountPercent"
            label="Discount Percent*"
            type="number"
            min="0"
            max="100"
            outlined
            hide-details
            class="mb-4"
          ></v-text-field>
          <v-text-field
            v-model="couponsDiscountAmount"
            label="Discount Amount*"
            type="number"
            min="0"
            outlined
            hide-details
            class="mb-4"
          ></v-text-field>
          <v-dialog
            v-model="datepickerDialog"
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="couponsExpireDate"
                label="Expire Date*"
                readonly
                outlined
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="couponsExpireDate"
              color="primary"
              @input="datepickerDialog = false"
            ></v-date-picker>
          </v-dialog>
          <v-checkbox
            v-model="couponsActive"
            label="Active"
            hide-details
            class="mt-n4"
          ></v-checkbox>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-card
      flat
      class="pa-3 mt-2"
    >
      <v-form class="multi-col-validation">
        <v-card-text class="pt-5">
          <v-row>
            <v-col
              cols="12"
              md="4"
            >
              <v-btn
                color="primary"
                class="me-3 mt-3"
                @click="addCoupons"
              >
                Add
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              offset-md="5"
              md="3"
            >
              <v-text-field
                v-model="couponsSearch"
                :append-icon="icons.mdiMagnify"
                label="Search"
                single-line
                hide-details
                dense
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-data-table
                :headers="couponsHeaders"
                :items="coupons"
                :items-per-page="5"
                item-key="full_name"
                :loading="couponsLoading"
                :search="couponsSearch"
              >
                <template #[`item.actions`]="{ item }">
                  <v-icon
                    small
                    class="me-2"
                    @click="editCoupons(item)"
                  >
                    {{ icons.mdiPencilOutline }}
                  </v-icon>
                </template>
                <template #[`item.expire_date`]="{item}">
                  <v-chip
                    v-if="isExpireDate(item.expire_date)"
                    color="primary"
                    class="v-chip-light-bg primary--text"
                  >
                    {{ $moment.unix(item.expire_date / 1000).format('DD/MM/YYYY') }}
                  </v-chip>
                  <v-chip
                    v-else
                    color="error"
                    class="v-chip-light-bg error--text"
                  >
                    {{ $moment.unix(item.expire_date / 1000).format('DD/MM/YYYY') }}
                  </v-chip>
                </template>
                <template #[`item.coupon`]="{item}">
                  <v-chip
                    v-if="item.active"
                    color="primary"
                    class="v-chip-light-bg primary--text"
                  >
                    {{ item.coupon }}
                  </v-chip>
                  <v-chip
                    v-else
                    color="error"
                    class="v-chip-light-bg error--text"
                  >
                    {{ item.coupon }}
                  </v-chip>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import { mdiMagnify, mdiCalendar, mdiPencilOutline, mdiDeleteOutline } from '@mdi/js'
import appSettingsMixin from '@/mixins/appSettingsMixin'

export default {
  mixins: [appSettingsMixin],
  setup() {
    return {
      icons: {
        mdiMagnify,
        mdiCalendar,
        mdiPencilOutline,
        mdiDeleteOutline,
      },
    }
  },
  data() {
    return {
      // coupons
      coupon: null,
      coupons: [],
      couponsHeaders: [
        { text: 'ACTIONS', value: 'actions', sortable: false, filterable: false },
        { text: 'COUPON', value: 'coupon' },
        { text: 'EXPIRE DATE', value: 'expire_date' },
        { text: 'MAX CLAIM', value: 'max_claim' },
        { text: 'CURRENT CLAIM', value: 'current_claim' },
        { text: 'DISCOUNT PERCENT', value: 'discount_percent' },
        { text: 'DISCOUNT AMOUNT', value: 'discount_amount' },
      ],
      couponsLoading: false,
      couponsSearch: null,
      couponsDialog: false,
      couponsIsEdit: false,
      couponsCoupon: null,
      couponsMaxClaim: 0,
      couponsDiscountPercent: 0,
      couponsDiscountAmount: 0,
      couponsExpireDate: null,
      couponsActive: false,
      couponsPouch: null,

      // utils
      datepickerDialog: false,
    }
  },
  async mounted() {
    this.couponsLoading = true
    await this.connectCoupons()
    await this.getCoupons()
    this.couponsLoading = false
  },
}
</script>
