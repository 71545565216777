<template>
  <div>
    <v-card
      flat
      class="pa-3 mt-2"
    >
      <v-form class="multi-col-validation">
        <v-card-text class="pt-5">
          <v-row>
            <v-col
              cols="12"
              offset-md="9"
              md="3"
            >
              <v-text-field
                v-model="demoMessageSearch"
                :append-icon="icons.mdiMagnify"
                label="Search"
                single-line
                hide-details
                dense
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-data-table
                :headers="demoMessageHeaders"
                :items="demoMessage"
                :items-per-page="5"
                item-key="created_at"
                :loading="demoMessageLoading"
                :search="demoMessageSearch"
                sort-by="created_at"
                sort-desc
              >
                <template #[`item.created_at`]="{item}">
                  <template v-if="item.created_at">
                    {{ $moment.unix(item.created_at / 1000).format('DD/MM/YYYY HH:mm:ss') }}
                  </template>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import { mdiMagnify } from '@mdi/js'
import appSettingsMixin from '@/mixins/appSettingsMixin'

export default {
  mixins: [appSettingsMixin],
  setup() {
    return {
      icons: {
        mdiMagnify,
      },
    }
  },
  data() {
    return {
      // demo message
      demoMessage: [],
      demoMessageHeaders: [
        { text: 'PHONE NUMBER', value: 'phone_number' },
        { text: 'MESSAGE', value: 'message' },
        { text: 'USER ID', value: 'user_id' },
        { text: 'FINGERPRINT ID', value: 'fingerprint_id' },
        { text: 'CREATED AT', value: 'created_at' },
      ],
      demoMessageLoading: false,
      demoMessageSearch: null,
    }
  },
  async mounted() {
    this.demoMessageLoading = true
    await this.connectDemoMessage()
    await this.getDemoMessage()
    this.demoMessageLoading = false
  },
}
</script>
