<template>
  <div>
    <v-card
      flat
      class="pa-3 mt-2"
    >
      <v-form class="multi-col-validation">
        <v-card-text class="pt-5">
          <v-row>
            <v-col
              cols="12"
            >
              <v-text-field
                v-model="freeTrialLengthValue"
                label="Free Trial Length (days)"
                hide-details
                dense
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-text>
          <v-btn
            color="primary"
            class="me-3 mt-3"
            @click="updateFreeTrial"
          >
            Update
          </v-btn>
        </v-card-text>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import { mdiMagnify } from '@mdi/js'
import appSettingsMixin from '@/mixins/appSettingsMixin'

export default {
  mixins: [appSettingsMixin],
  setup() {
    return {
      icons: {
        mdiMagnify,
      },
    }
  },
  data() {
    return {
      // free trial
      freeTrialLength: null,
      freeTrialLengthValue: null,
    }
  },
  async mounted() {
    await this.connectFreeTrial()
    await this.getFreeTrial()
  },
}
</script>
