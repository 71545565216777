<template>
  <div>
    <v-overlay
      :z-index="10000"
      :value="workspacesOverlay"
    >
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <v-dialog
      v-model="workspacesDialogDelete"
      width="500"
    >
      <v-card>
        <v-card-title>
          Warning
        </v-card-title>

        <v-card-text>
          Delete this workspace?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            outlined
            @click="workspacesDialogDelete = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            @click="axiosDeleteWorkspaces"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="workspacesDialog"
      width="600px"
      eager
    >
      <v-card>
        <v-card-actions>
          <h3 class="font-weight-bold">
            {{ workspacesIsEdit ? $t('edit') : $t('add') }} Workspace
          </h3>
          <v-spacer></v-spacer>
          <v-btn
            outlined
            class="mr-3"
            color="secondary"
            @click="workspacesDialog = false"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-btn
            v-if="!workspacesIsEdit"
            color="primary"
            @click="saveUserSubscription"
          >
            {{ $t('save') }}
          </v-btn>
          <v-btn
            v-if="workspacesIsEdit"
            color="primary"
            @click="updateUserSubscription"
          >
            {{ $t('update') }}
          </v-btn>
        </v-card-actions>
        <v-divider />
        <v-card-text>
          <v-text-field
            ref="refEmail"
            v-model="email"
            label="Email*"
            outlined
            hide-details="auto"
            :rules="rulesEmail"
            class="mb-4"
            :readonly="workspacesIsEdit"
          ></v-text-field>
          <v-select
            ref="refPackageList"
            v-model="packageList"
            :items="packageLists"
            label="Subscription*"
            item-text="package"
            item-value="_id"
            outlined
            return-object
            hide-details="auto"
            :rules="rules"
            class="mb-4"
          ></v-select>
          <v-text-field
            v-if="packageList && (packageList.package === 'Business' || packageList.package === 'Business+')"
            v-model="extra"
            outlined
            class="mb-n4"
            type="number"
            label="Extra"
          />
          <v-select
            ref="refDuration"
            v-model="duration"
            :items="durations"
            label="Duration*"
            item-text="text"
            item-value="value"
            outlined
            return-object
            hide-details="auto"
            :rules="rules"
            hint="Expire date = now + duration in month"
            persistent-hint
            class="mb-4"
          ></v-select>
          <v-dialog
            v-model="datepickerDialog"
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                ref="refExpireDate"
                v-model="expireDate"
                label="Expire Date"
                readonly
                outlined
                v-bind="attrs"
                hint="This will override duration field"
                persistent-hint
                clearable
                v-on="on"
                @click:clear="expireDate = null"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="expireDate"
              color="primary"
              @input="datepickerDialog = false"
            ></v-date-picker>
          </v-dialog>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-card
      flat
      class="pa-3 mt-2"
    >
      <v-form class="multi-col-validation">
        <v-card-text class="pt-5">
          <v-row>
            <v-col
              cols="12"
              md="4"
            >
              <v-btn
                color="primary"
                class="me-3 mt-3"
                @click="addWorkspaces"
              >
                Add
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              offset-md="5"
              md="3"
            >
              <v-text-field
                v-model="workspacesSearch"
                :append-icon="icons.mdiMagnify"
                label="Search"
                single-line
                hide-details
                dense
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-data-table
                :headers="workspacesHeaders"
                :items="workspaces"
                :items-per-page="5"
                :sort-by="['register_time']"
                :sort-desc="[true]"
                item-key="sub_id"
                :loading="workspacesLoading"
                :search="workspacesSearch"
              >
                <template #[`item.actions`]="{ item }">
                  <v-icon
                    small
                    class="me-2"
                    @click="editWorkspaces(item)"
                  >
                    {{ icons.mdiPencilOutline }}
                  </v-icon>
                  <v-icon
                    small
                    class="me-2"
                    @click="deleteWorkspaces(item)"
                  >
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                </template>
                <template #[`item.package`]="{ item }">
                  {{ item.subscription.is_free_trial ? 'free_trial' : item.package }}
                </template>
                <template #[`item.expire_date`]="{item}">
                  <template v-if="item.expire_date">
                    <v-chip
                      v-if="isExpireDate(item.expire_date)"
                      color="primary"
                      class="v-chip-light-bg primary--text"
                    >
                      {{ getExpireDate(item.expire_date) }}
                    </v-chip>
                    <v-chip
                      v-else
                      color="error"
                      class="v-chip-light-bg error--text"
                    >
                      {{ getExpireDate(item.expire_date) }}
                    </v-chip>
                  </template>
                </template>

                <template #[`item.register_time`]="{item}">
                  <template v-if="item.register_time">
                    <v-chip
                      color="error"
                      class="v-chip-light-bg error--text"
                    >
                      {{ $moment(item.register_time).format('DD/MM/YYYY') }}
                    </v-chip>
                    <!-- <v-chip
                      color="warning"
                      class="v-chip-light-bg warning--text"
                    >
                      {{ getExpireDate(item.register_time) }}
                    </v-chip> -->
                  </template>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import { mdiMagnify, mdiCalendar, mdiPencilOutline, mdiDeleteOutline, mdiClose } from '@mdi/js'
import appSettingsMixin from '@/mixins/appSettingsMixin'

export default {
  mixins: [appSettingsMixin],
  setup() {
    return {
      icons: {
        mdiMagnify,
        mdiCalendar,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiClose,
      },
    }
  },
  data() {
    return {
      // user subscription
      email: null,
      user: null,
      users: [],
      extra: 0,
      packageList: null,
      packageLists: [],
      duration: null,
      durations: [
        { text: '6 Months', value: 6 },
        { text: '12 Months', value: 12 },
      ],
      expireDate: null,

      // workspaces
      workspaces: [],
      workspacesHeaders: [
        { text: 'ACTIONS', value: 'actions', sortable: false, filterable: false },
        { text: 'WORKSPACE', value: 'workspace_name' },
        { text: 'EMAIL', value: 'email' },
        { text: 'SUBSCRIPTION', value: 'package' },
        { text: 'EXPIRE DATE', value: 'expire_date', filterable: false },
        { text: 'REGISTER TIME', value: 'register_time', filterable: true },
      ],
      workspacesLoading: false,
      workspacesOverlay: false,
      workspacesSearch: null,
      workspacesDialog: false,
      workspacesIsEdit: false,
      workspacesSubscription: null,
      workspacesDialogDelete: false,
      workspacesActive: null,

      // utils
      datepickerDialog: false,
      rulesEmail: [
        value => !!value || 'Required',
        value => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

          return pattern.test(value) || 'Invalid email'
        },
      ],
      rules: [value => !!value || 'Required'],
    }
  },
  computed: {
    usersItems() {
      if (this.users && this.users.length > 0) {
        return this.users.map(item => {
          return {
            // eslint-disable-next-line no-underscore-dangle
            text: item._id,
            value: item,
          }
        })
      }

      return []
    },
  },
  async mounted() {
    this.workspacesLoading = true

    await this.connectUsers()
    await this.getUsers()
    await this.getPackageLists()
    await this.connectUserSubscriptions()
    await this.getWorkspaces()
    await this.connectSubscriptions()
    //console.log(this.workspaces, `INI WORKSPACE`)
    this.workspacesLoading = false
  },
}
</script>
