import PouchDB from 'pouchdb-browser'
import PouchDBFind from 'pouchdb-find'
import TransformPouch from 'transform-pouch'

PouchDB.adapter('worker', require('worker-pouch'))

PouchDB.plugin(PouchDBFind)
PouchDB.plugin(TransformPouch)

export default {
  data() {
    return {
      remoteUsers: null,
      remoteUserSubscriptions: null,
      remoteSubscriptions: null,
      remoteCoupons: null,
      remoteDemoMessage: null,
      remoteFreeTrial: null,
    }
  },
  methods: {
    // user subscription
    async connectUsers() {
      const host = `${process.env.VUE_APP_COUCH_DB_DIRECT_URL}/users`
      const remote = new PouchDB(host, {
        fetch(url, opts) {
          opts.headers.set('Content-Type', 'application/json')

          return PouchDB.fetch(url, opts)
        },
      })

      this.remoteUsers = remote
    },
    async getUsers() {
      const allDocs = await this.remoteUsers.allDocs({
        include_docs: true,
      })

      this.users = allDocs.rows.map(row => row.doc)
    },
    async connectUserSubscriptions() {
      const host = `${process.env.VUE_APP_COUCH_DB_DIRECT_URL}/user_subscriptions`
      const remote = new PouchDB(host, {
        fetch(url, opts) {
          opts.headers.set('Content-Type', 'application/json')

          return PouchDB.fetch(url, opts)
        },
      })

      this.remoteUserSubscriptions = remote
    },
    async getUserSubscriptions() {
      const self = this
      const selector = {
        selector: {
          role: 'Admin',
        },
      }

      await this.remoteUserSubscriptions
        .createIndex({
          index: {
            fields: ['role'],
          },
        })
        .then(async function () {
          const allDocs = await self.remoteUserSubscriptions.find(selector)
          self.workspaces = allDocs.docs
        })
    },
    async getUserSubscriptionsByEmail(email) {
      const self = this
      let userSubscriptions = []
      const selector = {
        selector: {
          email,
          role: 'Admin',
        },
      }

      await this.remoteUserSubscriptions
        .createIndex({
          index: {
            fields: ['email', 'role'],
          },
        })
        .then(async function () {
          const allDocs = await self.remoteUserSubscriptions.find(selector)
          userSubscriptions = allDocs.docs
        })

      return userSubscriptions
    },
    async connectSubscriptions() {
      const host = `${process.env.VUE_APP_COUCH_DB_DIRECT_URL}/subscriptions`
      const remote = new PouchDB(host, {
        fetch(url, opts) {
          opts.headers.set('Content-Type', 'application/json')

          return PouchDB.fetch(url, opts)
        },
      })

      this.remoteSubscriptions = remote
    },
    async getSubscriptions(subId) {
      const self = this
      let subscriptions = []
      const selector = {
        selector: {
          sub_id: subId,
        },
      }

      await this.remoteSubscriptions
        .createIndex({
          index: {
            fields: ['sub_id'],
          },
        })
        .then(async function () {
          const allDocs = await self.remoteSubscriptions.find(selector)
          subscriptions = allDocs.docs
        })

      return subscriptions
    },
    async getWorkspaces() {
      const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/app-settings/get-workspaces`

      await this.$axios
        .get(endpoint)
        .then(response => {
          if (response.data.status) {
            this.workspaces = response.data.data
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    addWorkspaces() {
      this.workspacesDialog = true
      this.resetUserSubscription()
    },
    deleteWorkspaces(workspace) {
      this.workspacesDialogDelete = true
      this.workspacesActive = workspace
    },
    async editWorkspaces(workspace) {
      const workspaceOrigin = JSON.parse(JSON.stringify(workspace))

      this.workspacesDialog = true
      this.workspacesIsEdit = true
      this.workspacesSubscription = workspaceOrigin
      this.email = workspaceOrigin.email

      if (workspace.package) {
        const packageList = this.packageLists.find(item => item._id == workspace.package)

        if (packageList) {
          this.packageList = packageList
        }
      } else {
        this.packageList = null
      }

      if (workspace.package_month) {
        this.duration = {
          text: `${workspace.package_month} Months`,
          value: workspace.package_month,
        }
      } else {
        this.duration = null
      }

      if (workspace.expire_date) {
        this.expireDate = this.$moment.unix(workspace.expire_date / 1000).format('YYYY-MM-DD')
      } else {
        this.expireDate = null
      }
    },
    async axiosDeleteWorkspaces() {
      this.workspacesDialogDelete = false
      this.workspacesOverlay = true

      const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/app-settings/delete-workspaces`
      const payload = {
        user_subscription: this.workspacesActive,
      }

      await this.$axios
        .post(endpoint, payload)
        .then(async response => {
          if (response.data.status) {
            await this.getWorkspaces()
            this.workspacesActive = null
            this.$dialog.message.success('Delete workspace success')
          } else {
            this.$dialog.message.error('Failed to delete workspace')
          }
        })
        .catch(error => {
          console.log(error)

          this.$dialog.message.error('Failed to delete workspace, please check console log')
        })

      this.workspacesOverlay = false
    },
    resetUserSubscription() {
      this.workspacesIsEdit = false
      this.workspacesSubscription = null
      this.email = null
      this.packageList = null
      this.duration = null
      this.expireDate = null

      if (this.$refs.refEmail) {
        this.$refs.refEmail.resetValidation()
      }

      if (this.$refs.refPackageList) {
        this.$refs.refPackageList.resetValidation()
      }

      if (this.$refs.refDuration) {
        this.$refs.refDuration.resetValidation()
      }
    },
    async saveUserSubscription() {
      this.workspacesDialog = false
      this.workspacesOverlay = true

      if (this.email && this.packageList && this.duration) {
        const payload = {
          payload: {
            email: this.email,
            package_list: this.packageList,
            duration: this.duration,
            expire_date: this.expireDate,
            extra: this.extra,
          },
        }

        const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/app-settings/save-user-subscription`

        await this.$axios
          .post(endpoint, payload)
          .then(async response => {
            if (response.data.status) {
              const { data } = response.data

              if (data == 1) {
                await this.getWorkspaces()
                this.$dialog.message.success('Save user subscription success')
              } else if (data == 2) {
                this.$dialog.message.error('User subscription exists')
              } else if (data == 3) {
                this.$dialog.message.error('User subscription not found')
              } else if (data == 4) {
                await this.insertUser()
              }
            } else {
              this.$dialog.message.error('Failed to save user subscription')
            }
          })
          .catch(error => {
            console.log(error)

            this.$dialog.message.error('Failed to save user subscription, please check console log')
          })
      } else {
        this.$dialog.message.error('Please fill in all the required fields')
      }

      this.workspacesOverlay = false
    },
    async updateUserSubscription() {
      this.workspacesDialog = false
      this.workspacesOverlay = true

      if (this.packageList && this.duration) {
        const subscription = JSON.parse(JSON.stringify(this.workspacesSubscription))

        const payload = {
          payload: {
            subscription,
            package_list: this.packageList,
            duration: this.duration,
            expire_date: this.expireDate,
            extra: this.extra,
          },
        }

        const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/app-settings/update-user-subscription`

        await this.$axios
          .post(endpoint, payload)
          .then(async response => {
            if (response.data.status) {
              await this.getWorkspaces()
              this.$dialog.message.success('Update user subscription success')
            } else {
              this.$dialog.message.error('Failed to update user subscription')
            }
          })
          .catch(error => {
            console.log(error)

            this.$dialog.message.error('Failed to update user subscription, please check console log')
          })
      } else {
        this.$dialog.message.error('Please fill in all the required fields')
      }

      this.workspacesOverlay = false
    },
    async insertUser() {
      const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/login`
      const body = {
        email: this.email,
        name: this.email,
        image: `https://ui-avatars.com/api/?name=${this.email}&size=96&background=random`,
        skip_oauth2: true,
      }

      await this.$axios
        .post(endpoint, body)
        .then(async response => {
          if (response.data.status) {
            await this.saveUserSubscription()
          }
        })
        .catch(error => {
          console.log(error)
        })
    },

    // package lists
    async getPackageLists() {
      const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/app-settings/get-package-lists`

      await this.$axios
        .get(endpoint)
        .then(response => {
          if (response.data.status) {
            this.packageLists = response.data.data
            this.packageListsItems = JSON.parse(JSON.stringify(this.packageLists))
          }
        })
        .catch(error => {
          console.log(error)
        })
    },

    async savePackageLists() {
      this.packageListsOverlay = true

      const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/app-settings/save-package-lists`
      const payload = {
        package_lists: this.packageListsItems,
      }

      await this.$axios
        .post(endpoint, payload)
        .then(async response => {
          if (response.data.status) {
            await this.getPackageLists()
            this.$dialog.message.success('Save package lists success')
          } else {
            this.$dialog.message.error('Failed to save package lists')
          }
        })
        .catch(error => {
          console.log(error)

          this.$dialog.message.error('Failed to save package lists, please check console log')
        })

      this.packageListsOverlay = false
    },
    async resetPackageLists() {
      await this.getPackageLists()
      this.packageListsItems = JSON.parse(JSON.stringify(this.packageLists))
    },

    // coupons
    async connectCoupons() {
      const host = `${process.env.VUE_APP_COUCH_DB_DIRECT_URL}/coupons`
      const remote = new PouchDB(host, {
        fetch(url, opts) {
          opts.headers.set('Content-Type', 'application/json')

          return PouchDB.fetch(url, opts)
        },
      })

      this.remoteCoupons = remote
    },
    async getCoupons() {
      const allDocs = await this.remoteCoupons.allDocs({
        include_docs: true,
      })

      this.coupons = allDocs.rows.map(row => row.doc)
    },
    addCoupons() {
      this.couponsDialog = true
      this.resetCoupons()
    },
    editCoupons(coupon) {
      const couponOrigin = JSON.parse(JSON.stringify(coupon))

      this.couponsDialog = true
      this.couponsIsEdit = true
      this.couponsPouch = couponOrigin
      this.couponsCoupon = couponOrigin.coupon
      this.couponsMaxClaim = couponOrigin.max_claim
      this.couponsDiscountPercent = couponOrigin.discount_percent
      this.couponsDiscountAmount = couponOrigin.discount_amount
      this.couponsExpireDate = this.$moment.unix(couponOrigin.expire_date / 1000).format('YYYY-MM-DD')
      this.couponsActive = couponOrigin.active
    },
    resetCoupons() {
      this.couponsIsEdit = false
      this.couponsCoupon = null
      this.couponsMaxClaim = 0
      this.couponsDiscountPercent = 0
      this.couponsDiscountAmount = 0
      this.couponsExpireDate = null
      this.couponsActive = false
    },
    async saveCoupons() {
      const self = this

      if (
        !this.couponsCoupon ||
        !this.couponsExpireDate ||
        this.couponsMaxClaim === '' ||
        this.couponsDiscountPercent === '' ||
        this.couponsDiscountAmount === ''
      ) {
        this.$dialog.message.error('Please fill in all the required fields')
      } else {
        if (this.couponsIsEdit) {
          this.couponsPouch.coupon = this.couponsCoupon
          this.couponsPouch.expire_date = this.$moment(this.couponsExpireDate).valueOf()
          this.couponsPouch.max_claim = this.couponsMaxClaim
          this.couponsPouch.discount_percent = this.couponsDiscountPercent
          this.couponsPouch.discount_amount = this.couponsDiscountAmount
          this.couponsPouch.active = this.couponsActive
        } else {
          this.couponsPouch = {
            _id: this.couponsCoupon,
            coupon: this.couponsCoupon,
            expire_date: this.$moment(this.couponsExpireDate).valueOf(),
            max_claim: this.couponsMaxClaim,
            current_claim: 0,
            discount_percent: this.couponsDiscountPercent,
            discount_amount: this.couponsDiscountAmount,
            active: this.couponsActive,
          }
        }

        await this.remoteCoupons
          .put(this.couponsPouch)
          .then(async response => {
            await self.getCoupons()
            self.couponsDialog = false
            self.$dialog.message.success('Save coupons success')
          })
          .catch(error => {
            console.log(error)

            self.$dialog.message.error('Failed to save coupons')
          })
      }
    },

    // demo message
    async connectDemoMessage() {
      const host = `${process.env.VUE_APP_COUCH_DB_DIRECT_URL}/demo_message`
      const remote = new PouchDB(host, {
        fetch(url, opts) {
          opts.headers.set('Content-Type', 'application/json')

          return PouchDB.fetch(url, opts)
        },
      })

      this.remoteDemoMessage = remote
    },
    async getDemoMessage() {
      const allDocs = await this.remoteDemoMessage.allDocs({
        include_docs: true,
      })

      this.demoMessage = allDocs.rows.map(row => row.doc)
    },

    // free trial
    async connectFreeTrial() {
      const host = `${process.env.VUE_APP_COUCH_DB_DIRECT_URL}/free_trial`
      const remote = new PouchDB(host, {
        fetch(url, opts) {
          opts.headers.set('Content-Type', 'application/json')

          return PouchDB.fetch(url, opts)
        },
      })

      this.remoteFreeTrial = remote
    },
    async getFreeTrial() {
      const allDocs = await this.remoteFreeTrial.allDocs({
        include_docs: true,
      })

      const rows = allDocs.rows.map(row => row.doc)

      this.freeTrialLength = rows.find(function (row) {
        return row._id == 'free_trial_length'
      })

      this.freeTrialLengthValue = this.freeTrialLength.value
    },
    async updateFreeTrial() {
      const self = this

      this.freeTrialLength.value = this.freeTrialLengthValue

      await this.remoteFreeTrial
        .put(this.freeTrialLength)
        .then(async response => {
          await self.getFreeTrial()
          self.$dialog.message.success('Update free trial success')
        })
        .catch(error => {
          console.log(error)

          self.$dialog.message.error('Failed to update free trial')
        })
    },

    // utils
    isExpireDate(expireDate) {
      if (expireDate) {
        const expiredDate = this.$moment.unix(expireDate / 1000).format()
        const isExpired = this.$moment().isBefore(expiredDate)

        return isExpired
      }

      return false
    },
    getExpireDate(expireDate) {
      if (expireDate) {
        return this.$moment.unix(expireDate / 1000).format('DD/MM/YYYY')
      }

      return ''
    },
  },
}
