<template>
  <div>
    <v-card class="d-flex align-center justify-center text-center" :class="{ 'auth-card': !isAuth, 'mb-4': isAuth }">
      <v-card-text class="primary--text">
        <p class="text-2xl font-weight-semibold mb-2">
          App Settings
        </p>
        <template v-if="!isAuth">
          <p>This content is password protected. To view it please enter your password below:</p>
          <v-row class="justify-center">
            <v-col cols="12" md="4">
              <v-form @submit.prevent="checkAuth" class="mx-auto">
                <v-text-field
                  v-model="key"
                  :type="isPasswordVisible ? 'text' : 'password'"
                  :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                  label="Password"
                  outlined
                  @click:append="isPasswordVisible = !isPasswordVisible"
                  hide-details
                ></v-text-field>
              </v-form>
            </v-col>
          </v-row>
          <v-btn
            color="primary"
            class="mt-4"
            @click="checkAuth"
          >
            Submit
          </v-btn>
        </template>
      </v-card-text>
    </v-card>
    <v-card v-if="isAuth">
      <!-- tabs -->
      <v-tabs
        v-model="tab"
        show-arrows
      >
        <v-tab
          v-for="tab in tabs"
          :key="tab.icon"
        >
          <v-icon
            size="20"
            class="me-3"
          >
            {{ tab.icon }}
          </v-icon>
          <span>{{ tab.title }}</span>
        </v-tab>
      </v-tabs>

      <!-- tabs item -->
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <app-settings-user-subscription></app-settings-user-subscription>
        </v-tab-item>
        <v-tab-item>
          <app-settings-package-lists></app-settings-package-lists>
        </v-tab-item>
        <v-tab-item>
          <app-settings-coupons></app-settings-coupons>
        </v-tab-item>
        <v-tab-item>
          <app-settings-demo-message></app-settings-demo-message>
        </v-tab-item>
        <v-tab-item>
          <app-settings-free-trial></app-settings-free-trial>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import {
  mdiTicketPercent,
  mdiEyeOffOutline,
  mdiEyeOutline,
  mdiViewList,
  mdiAccountPlus,
  mdiMessage,
  mdiCash,
} from '@mdi/js'

import AppSettingsUserSubscription from './AppSettingsUserSubscription.vue'
import AppSettingsPackageLists from './AppSettingsPackageLists.vue'
import AppSettingsCoupons from './AppSettingsCoupons.vue'
import AppSettingsDemoMessage from './AppSettingsDemoMessage.vue'
import AppSettingsFreeTrial from './AppSettingsFreeTrial.vue'

export default {
  name: 'AppSettings',
  components: {
    AppSettingsUserSubscription,
    AppSettingsPackageLists,
    AppSettingsCoupons,
    AppSettingsDemoMessage,
    AppSettingsFreeTrial,
  },
  setup() {
    return {
      icons: {
        mdiTicketPercent,
        mdiEyeOffOutline,
        mdiEyeOutline,
        mdiViewList,
        mdiAccountPlus,
        mdiMessage,
        mdiCash,
      },
    }
  },
  data() {
    return {
      key: null,
      isAuth: false,
      isPasswordVisible: false,
      tab: '',
      tabs: [
        { title: 'User Subscription', icon: mdiAccountPlus },
        { title: 'Package Lists', icon: mdiViewList },
        { title: 'Coupons', icon: mdiTicketPercent },
        { title: 'Demo Message', icon: mdiMessage },
        { title: 'Free Trial', icon: mdiCash },
      ],
      appSettingData: {},
    }
  },
  methods: {
    checkAuth() {
      if (this.key === process.env.VUE_APP_APP_SETTINGS_KEY) {
        this.isAuth = true
      } else {
        this.isAuth = false
        this.$dialog.message.error('Wrong password')
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.auth-card {
  height: 97.4vh;
}
</style>
