<template>
  <div>
    <v-overlay :value="packageListsOverlay">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <v-card
      flat
      class="pa-3 mt-2"
    >
      <v-form class="multi-col-validation">
        <v-card-text class="pt-5">
          <v-row>
            <v-col cols="12">
              <v-expansion-panels focusable>
                <v-expansion-panel
                  v-for="(item, i) in packageListsItems"
                  :key="item.id"
                >
                  <v-expansion-panel-header>{{ item.package }}</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row>
                      <v-col cols="12" md="6">
                        <span>Price</span>
                        <v-text-field
                          v-model="item.price.base_price"
                          label="Base Price"
                          type="number"
                          outlined
                          hide-details
                          class="mt-4 mb-4"
                        ></v-text-field>
                        <v-text-field
                          v-model="item.price['6_months_discount']"
                          label="6 Months Discount (%)"
                          type="number"
                          outlined
                          hide-details
                          class="mb-4"
                        ></v-text-field>
                        <v-text-field
                          v-model="item.price['12_months_discount']"
                          label="12 Months Discount (%)"
                          type="number"
                          outlined
                          hide-details
                          class="mb-4"
                        ></v-text-field>
                        <!-- <v-text-field
                          v-model="item.price.per_month"
                          label="Per Month"
                          type="number"
                          outlined
                          hide-details
                          class="mt-4 mb-4"
                        ></v-text-field>
                        <v-text-field
                          v-model="item.price.per_month_discount"
                          label="Per Month Discount"
                          type="number"
                          outlined
                          hide-details
                          class="mb-4"
                        ></v-text-field>
                        <v-text-field
                          v-model="item.price['6_months']"
                          label="6 Months"
                          type="number"
                          outlined
                          hide-details
                          class="mb-4"
                        ></v-text-field>
                        <v-text-field
                          v-model="item.price['12_months']"
                          label="12 Months"
                          type="number"
                          outlined
                          hide-details
                        ></v-text-field> -->
                      </v-col>
                      <v-col cols="12" md="6">
                        <span>Access</span>
                        <v-text-field
                          v-model="item.access.max_channel"
                          label="Max Channel"
                          type="number"
                          outlined
                          hide-details
                          class="mt-4 mb-4"
                        ></v-text-field>
                        <v-text-field
                          v-model="item.access.max_operator"
                          label="Max Operator"
                          type="number"
                          outlined
                          hide-details
                          class="mb-4"
                        ></v-text-field>
                        <v-text-field
                          v-model="item.access.max_contact"
                          label="Max Contact"
                          type="number"
                          outlined
                          hide-details
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-divider class="my-5"></v-divider>
                    <v-row>
                      <v-col cols="12" md="6">
                        <span>Access</span>
                        <v-checkbox
                          v-model="item.access.channel"
                          label="Channel"
                          hide-details
                        ></v-checkbox>
                        <v-checkbox
                          v-model="item.access.inbox"
                          label="Inbox"
                          hide-details
                        ></v-checkbox>
                        <v-checkbox
                          v-model="item.access.contacts"
                          label="Contacts"
                          hide-details
                        ></v-checkbox>
                        <v-checkbox
                          v-model="item.access.broadcast"
                          label="Broadcast"
                          hide-details
                        ></v-checkbox>
                        <v-checkbox
                          v-model="item.access.templates"
                          label="Templates"
                          hide-details
                        ></v-checkbox>
                        <v-checkbox
                          v-model="item.access.operators"
                          label="Operators"
                          hide-details
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="12" md="6">
                        <span>Feature Plus</span>
                        <v-checkbox
                          v-for="(plus, index) in item.feature.Plus"
                          :key="index"
                          v-model="item.feature.Plus[index].enabled"
                          :label="item.feature.Plus[index].name"
                          hide-details
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                    <v-divider class="my-5"></v-divider>
                    <v-row>
                      <v-col cols="12" md="6">
                        <span>Feature Channel</span>
                        <v-checkbox
                          v-for="(channel, index) in item.feature.Channel"
                          :key="index"
                          v-model="item.feature.Channel[index].enabled"
                          :label="item.feature.Channel[index].name"
                          hide-details
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="12" md="6">
                        <span>Feature Broadcast</span>
                        <v-checkbox
                          v-for="(broadcast, index) in item.feature.Broadcast"
                          :key="index"
                          v-model="item.feature.Broadcast[index].enabled"
                          :label="item.feature.Broadcast[index].name"
                          hide-details
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                    <v-divider class="my-5"></v-divider>
                    <v-row>
                      <v-col cols="12" md="6">
                        <span>Feature Inbox</span>
                        <v-checkbox
                          v-for="(inbox, index) in item.feature.Inbox"
                          :key="index"
                          v-model="item.feature.Inbox[index].enabled"
                          :label="item.feature.Inbox[index].name"
                          hide-details
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="12" md="6">
                        <span>Feature Contacts</span>
                        <v-checkbox
                          v-for="(contacts, index) in item.feature.Contacts"
                          :key="index"
                          v-model="item.feature.Contacts[index].enabled"
                          :label="item.feature.Contacts[index].name"
                          hide-details
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-text>
          <v-btn
            color="primary"
            class="me-3 mt-3"
            @click="savePackageLists"
          >
            Save
          </v-btn>
          <v-btn
            outlined
            class="mt-3"
            color="secondary"
            @click="resetPackageLists"
          >
            Reset
          </v-btn>
        </v-card-text>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import appSettingsMixin from '@/mixins/appSettingsMixin'

export default {
  mixins: [appSettingsMixin],
  data() {
    return {
      packageLists: [],
      packageListsItems: [],
      packageListsOverlay: false,
      panel: [],
    }
  },
  async mounted() {
    await this.getPackageLists()
  },
}
</script>
